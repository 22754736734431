import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../components/Button";
import { EventsSlider } from "../components/EventsSlider";
import Loader from "../components/Loader";
import { Title } from "../components/Title";
import axiosInstance from "../helpers/axiosInstance";
import Layout from "../layouts/Layout";

import {
  EVENT_FORMAT,
  IEvent,
  IEventOwnerProfileOrganization,
  IEventType,
} from "../types/IEvent";
import { useAppDispatch, useAppSelector } from "../hooks/redux";

import { convertDate, getTime } from "../helpers";
import { findEventsOnCategory } from "../store/reducers/eventsSlice";
import { FromEditor } from "../components/FromEditor";

import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { EventEmitter } from "stream";
import { sendMetriks } from "../utils/metriks";

interface IBlockText {
  type: string;
  data: {
    text: string;
  };
}

const EventPage = () => {
  const dispatch = useAppDispatch();
  let { id } = useParams();

  const [event, setEvent] = useState<IEvent | null>(null);
  const [organization, setOrganization] =
    useState<IEventOwnerProfileOrganization | null>(null);
  const [eventType, setEventType] = useState<IEventType | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await axiosInstance.get(`/api/event-all/${id}`);
      const organizationResponse = await axiosInstance.get(
        `/api/organization/${response.data.owner.id}`
      );
      const eventTypeResponse = await axiosInstance.get(
        `/api/event-type/${response.data.event_type}`
      );
      setEvent(response.data);
      setOrganization(organizationResponse.data);
      setEventType(eventTypeResponse.data);

      // Сбрасываю фильтры по событиям
      if (event !== null) {
        dispatch(findEventsOnCategory("all"));
      }
      // Скролл к началу страницы
      window.scrollTo(0, 0);
    }
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    if (event) {
      document.title = event.name ?? '';

      const metaTags = [
        { property: "og:title", content: event.name ?? "" },
        { property: "og:description", content: event.address ?? "" },
        { property: "og:image", content: getEventImage() ?? "" },
        { property: "og:url", content: window.location.href },
        { property: "og:type", content: "website" },
      ];

      metaTags.forEach(({ property, content }) => {
        let element = document.querySelector(`meta[property="${property}"]`);
        if (!element) {
          element = document.createElement("meta");
          element.setAttribute("property", property);
          document.head.appendChild(element);
        }
        element.setAttribute("content", content);
      });
    }
  }, [event]);

  // Events
  const { events } = useAppSelector((state) => state.eventsReducer);
  const eventsWithoutCurrent = () =>
    event && event.id ? events.filter((e) => e.id !== event.id) : events;
  const formatPrice = (price: string) => Math.round(parseFloat(price));
  const isEventFree = event?.price === "0.00" || event?.price === null;

  const getEventImage = () => {
    if (event?.image_event) return event.image_event;
    if (event?.image) return event.image;
    return '';
  };

  const formatLang = (lang: string) =>
    lang === "French" ? "Французский" : "Русский";

  const formatLoction = (location: string) =>
    location.split(",").map((str) => parseFloat(str));

  let textBlocks = null;
  if (event?.description) {
    try {
      textBlocks = JSON.parse(event?.description).blocks;
    } catch (error) {
      console.log(error);
    }
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <div className="container pt-12">
        {event ? (
          <>
            <Title
              text={event.name}
              level={1}
              className="text-2xl md:text-5xl font-semibold mb-6"
            />
            <div className="flex flex-col lg:flex-row justify-between gap-10 border-t border-gray-400">
              <div className="border-b border-gray-400 flex w-full pb-5 flex-col md:flex-row">
                <div className="flex flex-col lg:flex-row items-start gap-10 w-full mr-10 mt-3">
                  <div className="mt-5 w-full lg:w-1/2">
                    <img
                      src={
                        event.image_event
                          ? event.image_event
                          : event.image
                            ? event.image
                            : eventType?.image
                      }
                      alt={event.name}
                      className="w-full h-[300px] object-cover rounded-lg shadow-2xl"
                    />
                  </div>
                  <div className="hidden lg:flex w-1/2 mt-5">
                    {event.location && (
                      <YMaps>
                        <Map
                          defaultState={{
                            center: formatLoction(event.location),
                            zoom: 16,
                            controls: ["zoomControl", "fullscreenControl"],
                          }}
                          className="w-full h-[300px] rounded-lg shadow-2xl"
                          modules={[
                            "control.ZoomControl",
                            "control.FullscreenControl",
                          ]}
                        >
                          <Placemark
                            modules={["geoObject.addon.balloon"]}
                            defaultGeometry={formatLoction(event.location)}
                            properties={{
                              balloonContentBody: event.address
                                ? event.address
                                : "",
                            }}
                          />
                        </Map>
                      </YMaps>
                    )}
                  </div>
                </div>
                <div className="md:w-[330px] h-fit lg:mb-10 shrink-0">
                  {event.date && (
                    <div className="mt-5 pl-2">
                      <span className="font-dela text-3xl flex flex-wrap">
                        <span>{convertDate(event.date)}</span>
                        <span>
                          {event.date ? ` Начало в ${getTime(event.date)}` : null}
                        </span>
                      </span>
                    </div>
                  )}
                  {(event.event_format === EVENT_FORMAT.ONLINE ||
                    event.address) && (
                        <div className="mt-5 px-2 flex flex-nowrap items-center">
                          <div>
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 34.5455C20 34.5455 32.7273 25.2727 32.7273 15.2273C32.7273 8.82577 27.0291 3.63637 20 3.63637C12.9709 3.63637 7.27271 8.82577 7.27271 15.2273C7.27271 25.2727 20 34.5455 20 34.5455Z"
                                fill="#E52D37"
                                stroke="#E52D37"
                                strokeWidth="2"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20 20.8333C22.7614 20.8333 25 18.5947 25 15.8333C25 13.0719 22.7614 10.8333 20 10.8333C17.2386 10.8333 15 13.0719 15 15.8333C15 18.5947 17.2386 20.8333 20 20.8333Z"
                                fill="white"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="ml-1">
                            {event.event_format === EVENT_FORMAT.ONLINE && (
                              <span>Онлайн</span>
                            )}
                            {event.event_format !== EVENT_FORMAT.ONLINE &&
                              event.address && <span>{event.address}</span>}
                            <div className="lg:hidden text-customBlue font-semibold border-b border-dotted border-customBlue w-fit" onClick={openModal}>Показать на карте</div>
                          </div>
                        </div>
                    )}
                  <div className="mt-5 px-2 flex flex-nowrap items-center">
                    <div className="mx-1">
                      <svg
                        width="30"
                        height="27"
                        viewBox="0 0 30 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="29.7838"
                          height="26.8781"
                          rx="2"
                          fill="#E52D37"
                        />
                        <path
                          d="M16.375 18.9583H21.375M16.375 18.9583L15.375 21.25M16.375 18.9583L18.875 13L21.375 18.9583M21.375 18.9583L22.375 21.25"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11 4.75L11.4375 6.125"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.625 7.04167H16.25"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.375 9.33333C8.375 9.33333 9.15791 12.2029 11.1152 13.7971C13.0724 15.3913 16.25 16.6667 16.25 16.6667"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.5 7.04167C14.5 7.04167 13.7171 10.808 11.7598 12.9004C9.80265 14.9927 6.625 16.6667 6.625 16.6667"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <ul className="ml-2">
                      <li className="font-montserrat font-bold">
                        {event.language && (
                          <div>
                            <span>Язык события: </span>
                            <span>{formatLang(event.language)}</span>
                            {event.custom_language && (
                              <span>&nbsp;({event.custom_language})</span>
                            )}
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5 px-2 flex flex-nowrap items-center">
                    <div className="mx-1">
                      <svg
                        width="30"
                        height="27"
                        viewBox="0 0 30 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="29.7838"
                          height="26.8781"
                          rx="2"
                          fill="#E52D37"
                        />
                        <g clipPath="url(#clip0_2004_3)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.7432 8.48834L17.9192 5.5L19.6488 8.49584L12.7432 8.48834Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.5 9.25C7.5 8.83578 7.83579 8.5 8.25 8.5H21.75C22.1642 8.5 22.5 8.83578 22.5 9.25V19.75C22.5 20.1642 22.1642 20.5 21.75 20.5H8.25C7.83579 20.5 7.5 20.1642 7.5 19.75V9.25Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.2188 16.375H22.5V12.625H19.2188C18.1314 12.625 17.25 13.4645 17.25 14.5C17.25 15.5355 18.1314 16.375 19.2188 16.375Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M22.5 10.1875V19.1875"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2004_3">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="translate(6 4)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="ml-2">
                      {!isEventFree ? (
                        <p className="font-montserrat font-bold">
                          {event.price_max ? (
                            <span>
                              От {formatPrice(event.price)} до{" "}
                              {formatPrice(event.price_max)}
                            </span>
                          ) : (
                            <span>{formatPrice(event.price)}</span>
                          )}{" "}
                          рублей
                        </p>
                      ) : (
                        <p className="font-montserrat font-bold">
                          Посещение бесплатное!
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mx-1 flex mt-5 mb-5 px-2">
                    {event.price ? (
                      <a
                        href={event.link}
                        target="_blank"
                        onClick={() => sendMetriks('reachGoal', 'visit event')}
                        className="flex items-center justify-center custom_red text-white w-72 h-14 rounded font-dela text-3xl text-center" rel="noreferrer"
                      >
                        Хочу туда!
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {/* <img
              src={
                event.image_event
                  ? event.image_event
                  : event.image
                  ? event.image
                  : eventType?.image
              }
              alt={event.name}
              className="w-full max-h-[450px] hidden md:block object-cover object-center mb-10"
            /> */}

            <div className="mt-5 px-2">
              <p className="text-xl font-dela mb-3 text-gray-800">
                О событии:{" "}
              </p>
              {textBlocks &&
                textBlocks.map((block: IBlockText, index: number) => {
                  return (
                    <FromEditor
                      type={block.type}
                      data={block.data}
                      key={index}
                    />
                  );
                })}
            </div>
            <div>
              {organization?.name && (
                <ul className="flex flex-col">
                  <li className="inline-flex items-center gap-x-2 py-3 bg-white text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                    <div>
                      <p className="text-xl font-dela mb-3">
                        Об организаторе:{" "}
                      </p>
                      <div className="flex">
                        {organization?.logo && (
                          // eslint-disable-next-line jsx-a11y/alt-text
                          <img
                            src={organization.logo}
                            className="h-24 w-24 mr-2 self-start shrink-0"
                          />
                        )}
                        <div>
                          <span className="font-bold">
                            {organization.name_short
                              ? organization.name_short
                              : organization.name}
                          </span>
                          {organization.description && (
                            <p>{organization.description}</p>
                          )}
                          <p className="font-bold mt-4">Контакты:</p>
                          {organization.phone && (
                            <a href={`tel:${organization.phone}`}>
                              {organization.phone}
                            </a>
                          )}
                          {organization.contact_email && (
                            <p className="mt-2">
                              <a href={`mailto:${organization.contact_email}`}>
                                {organization.contact_email}
                              </a>
                            </p>
                          )}
                          <div className="flex gap-3">
                            {organization.telegram && (
                              <a href={organization.telegram}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"></path>
                                </svg>
                              </a>
                            )}
                            {organization.vk && (
                              <a href={organization.vk}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M21.579 6.855c.14-.465 0-.806-.662-.806h-2.193c-.558 0-.813.295-.953.619 0 0-1.115 2.719-2.695 4.482-.51.513-.743.675-1.021.675-.139 0-.341-.162-.341-.627V6.855c0-.558-.161-.806-.626-.806H9.642c-.348 0-.558.258-.558.504 0 .528.79.65.871 2.138v3.228c0 .707-.127.836-.407.836-.743 0-2.551-2.729-3.624-5.853-.209-.607-.42-.852-.98-.852H2.752c-.627 0-.752.295-.752.619 0 .582.743 3.462 3.461 7.271 1.812 2.601 4.363 4.011 6.687 4.011 1.393 0 1.565-.313 1.565-.853v-1.966c0-.626.133-.752.574-.752.324 0 .882.164 2.183 1.417 1.486 1.486 1.732 2.153 2.567 2.153h2.192c.626 0 .939-.313.759-.931-.197-.615-.907-1.51-1.849-2.569-.512-.604-1.277-1.254-1.51-1.579-.325-.419-.231-.604 0-.976.001.001 2.672-3.761 2.95-5.04z"></path>
                                </svg>
                              </a>
                            )}
                          </div>
                          {organization.website && (
                            <p className="mt-2">
                              <a href={organization.website}>
                                {organization.website}
                              </a>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              )}
            </div>

            {/* <Tabs value="info" className="mt-8">
              <TabsHeader>
                <Tab value="info">О событии</Tab>
                {event.owner && <Tab value="organizer">Об организаторе</Tab>}
              </TabsHeader>
              <TabsBody>
                <TabPanel value="info">
                  {event.date && (
                    <div className="">
                      <p className="font-bold">Дата проведения: </p>
                      <span>
                        {convertDate(event.date)}
                        {getTime(event.date)
                          ? `, начало в ${getTime(event.date)}`
                          : null}
                      </span>
                    </div>
                  )}

                  {event.address && (
                    <div className="mt-4">
                      <p className="font-bold">Место: </p>
                      <span>{event.address}</span>
                    </div>
                  )}

                  {event.language && (
                    <div className="mt-4">
                      <p className="font-bold">Язык события: </p>
                      <span>{formatLang(event.language)}</span>
                      {event.custom_language && (
                        <span>&nbsp;({event.custom_language})</span>
                      )}
                    </div>
                  )}

                  <div className="mt-4">
                    <p className="font-bold">Стоимость: </p>
                    {event.price ? (
                      <span>
                        {" "}
                        {event.price_max ? (
                          <span>
                            От {formatPrice(event.price)} до{" "}
                            {formatPrice(event.price_max)}
                          </span>
                        ) : (
                          <span>{formatPrice(event.price)}</span>
                        )}{" "}
                        рублей
                      </span>
                    ) : (
                      <span>Бесплатно!</span>
                    )}
                  </div>
                </TabPanel>
                <TabPanel value="organizer">
                  {event.owner?.profile?.organization?.name && (
                    <div className="mt-4">
                      <p className="font-bold">Название: </p>
                      <span>{event.owner?.profile?.organization.name}</span>
                    </div>
                  )}

                  {event.owner?.profile?.organization && (
                    <div className="mt-4">
                      <p className="font-bold">Контакты: </p>

                      {event.owner?.profile?.organization.phone ? (
                        <a
                          href={`tel:${event.owner?.profile?.organization.phone}`}
                        >
                          {event.owner?.profile?.organization.phone}
                        </a>
                      ) : null}

                      {event.owner?.profile?.organization.contact_email ? (
                        <p className="mt-2">
                          <a
                            href={`mailto:${event.owner?.profile?.organization.contact_email}`}
                          >
                            {event.owner?.profile?.organization.contact_email}
                          </a>
                        </p>
                      ) : null}

                      {event.owner?.profile?.organization.telegram ? (
                        <a href={event.owner?.profile?.organization.telegram}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"></path>
                          </svg>
                        </a>
                      ) : null}

                      {event.owner?.profile?.organization.vk ? (
                        <a href={event.owner?.profile?.organization.vk}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M21.579 6.855c.14-.465 0-.806-.662-.806h-2.193c-.558 0-.813.295-.953.619 0 0-1.115 2.719-2.695 4.482-.51.513-.743.675-1.021.675-.139 0-.341-.162-.341-.627V6.855c0-.558-.161-.806-.626-.806H9.642c-.348 0-.558.258-.558.504 0 .528.79.65.871 2.138v3.228c0 .707-.127.836-.407.836-.743 0-2.551-2.729-3.624-5.853-.209-.607-.42-.852-.98-.852H2.752c-.627 0-.752.295-.752.619 0 .582.743 3.462 3.461 7.271 1.812 2.601 4.363 4.011 6.687 4.011 1.393 0 1.565-.313 1.565-.853v-1.966c0-.626.133-.752.574-.752.324 0 .882.164 2.183 1.417 1.486 1.486 1.732 2.153 2.567 2.153h2.192c.626 0 .939-.313.759-.931-.197-.615-.907-1.51-1.849-2.569-.512-.604-1.277-1.254-1.51-1.579-.325-.419-.231-.604 0-.976.001.001 2.672-3.761 2.95-5.04z"></path>
                          </svg>
                        </a>
                      ) : null}

                      {event.owner?.profile?.organization.website ? (
                        <p className="mt-2">
                          <a href={event.owner?.profile?.organization.website}>
                            {event.owner?.profile?.organization.website}
                          </a>
                        </p>
                      ) : null}
                    </div>
                  )}
                </TabPanel>
              </TabsBody>
            </Tabs> */}
          </>
        ) : (
          <Loader />
        )}

        <div className="main__events mt-20">
          <Title
            text="Другие события"
            level={2}
            className="text-2xl md:text-5xl font-semibold mb-8"
          />
          <EventsSlider events={eventsWithoutCurrent()} />
        </div>

        {isModalOpen && (
          <div
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
            onClick={closeModal}
          >
            <div
              className="bg-white p-4 rounded-lg w-full max-w-lg relative"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className="absolute top-2 right-2 text-gray-600"
                onClick={closeModal}
              >
                x
              </button>
              {event?.location && (
                <YMaps>
                  <Map
                    defaultState={{
                      center: formatLoction(event.location),
                      zoom: 16,
                      controls: ["zoomControl", "fullscreenControl"],
                    }}
                    className="w-full h-[300px] rounded-lg shadow-2xl"
                    modules={[
                      "control.ZoomControl",
                      "control.FullscreenControl",
                    ]}
                  >
                    <Placemark
                      modules={["geoObject.addon.balloon"]}
                      defaultGeometry={formatLoction(event.location)}
                      properties={{
                        balloonContentBody: event.address ? event.address : "",
                      }}
                    />
                  </Map>
                </YMaps>
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default EventPage;
